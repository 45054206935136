import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  appointmentForm: FormGroup;
  sectionTitle: string;
  sectionSubtitle: string;
  sectionDescription: string;
  formButtonText: string;
  backgroundImageUrl: string;
  
  constructor(private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.appointmentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });

    // Example dynamic data
    this.sectionTitle = 'We Are Here For You To Make An Appointment';
    this.sectionSubtitle = 'Appointment';
    this.sectionDescription = 'Schedule your appointment with ease and confidence!';
    this.formButtonText = 'Send a Request';
    this.backgroundImageUrl = 'path/to/your/background/image.jpg'; // Update with your actual image path
  }

  onSubmit(): void {
    if (this.appointmentForm.valid) {
      const formData = this.appointmentForm.value;

      // EmailJS params
      const emailParams = {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message
      };

      // Send the email via EmailJS
      emailjs.send(
        'service_pw30jgi',     // Replace with your EmailJS Service ID
        'template_gw8ec63',    // Replace with your EmailJS Template ID
        emailParams,
        'FyKuWmyXSGb46qy5v'         // Replace with your EmailJS User ID
      ).then((response: EmailJSResponseStatus) => {
        console.log('Email sent successfully!', response.status, response.text);
        this.toastr.success('Your appointment request has been sent successfully!');
        this.appointmentForm.reset();
      }).catch((error) => {
        console.error('Error sending email:', error);
        this.toastr.error('Failed to send appointment request. Please try again.');
      });

    } else {
      this.validateFields();
    }
  }

  validateFields(): void {
    const errors = {
      name: this.appointmentForm.get('name').invalid,
      email: this.appointmentForm.get('email').invalid,
      message: this.appointmentForm.get('message').invalid
    };

    for (const field in errors) {
      if (errors[field]) {
        this.showToast(field);
        break; // Show the first error only
      }
    }
  }

  showToast(field: string): void {
    switch (field) {
      case 'name':
        this.toastr.error('Name is required.');
        break;
      case 'email':
        this.toastr.error('Please enter a valid email address.');
        break;
      case 'message':
        this.toastr.error('Message is required.');
        break;
    }
  }
}
