<div class="page-title-area products_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">Nutriget-L Drops</h2>
            <ul>
                <li><a href="/products">Products</a></li>
                <li>Nutriget-L Drops Details</li>
            </ul>
        </div>
    </div>
  
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image"><a><img
                                alt="image" src="assets/img/products/3.png"></a>
                        <div class="date">Nutriget-L Drops</div>
                    </div>
                   
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="product-details-desc about-content">
                    <h3>NUTRIGET™-L Drops</h3>
                    <div class="highlight">
                        <h5>Healthy Option For Complete Diet</h5>
                    </div>
            
                    <div class="d-flex cvvv">
                        <div><strong>Vitamin C:</strong> 40 mg<br>
                            <strong>L-Lysine:</strong> 10 mg<br>
                            <strong>Vitamin B3:</strong> 8 mg<br>
                            <strong>Vitamin E:</strong> 5 mg<br>
                            <strong>Zinc:</strong> 5 mg<br>
                            <strong>Flaxseed Oil:</strong> 3 mg<br>
                            <strong>Vitamin B5:</strong> 1.8 mg<br></div>
                            <div class="ms-5">
                    <strong>Vitamin B6:</strong> 0.9 mg<br>
                    <strong>Vitamin B2:</strong> 0.6 mg<br>
                    <strong>Vitamin B1:</strong> 0.5 mg<br>
                    <strong>Vitamin A:</strong> 0.4 mg<br>
                    <strong>Biotin:</strong> 8 mg<br>
                    <strong>Vitamin D:</strong> 5 mcg<br>
                    <strong>Vitamin B12:</strong> 1 mcg</div>
                </div>
                    <div class="section-heading">Key Ingredients</div>
                    <ul>
                        <li><strong>Flaxseed Oil:</strong> Rich in OMEGA fats that play a significant role in the healthy functioning of the brain, heart, and other vital organs.</li>
                        <li><strong>L-Lysine:</strong> Promotes growth, helps to increase height and weight.</li>
                        <li><strong>Multivitamins:</strong> Prepare the body to fight against diseases & helps to boost immunity.</li>
                        <li><strong>Zinc:</strong> Plays a crucial role in the growth and development of children & is helpful in the treatment of diarrhea.</li>
                    </ul>
            
                    <div class="benefits">
                        <div class="section-heading"><b>Benefits</b></div>
                        <p>NUTRIGET™-L is a unique blend of Multivitamins, Amino Acid, Zinc, and Flaxseed Oil. It is highly helpful in preventing vitamin deficiencies. This blend improves bone health and promotes healthy growth and development in infants.</p>
                    </div>
            
                    <div class="uses">
                        <div class="section-heading">Indicated For</div>
                        <ul>
                            <li>General Weakness</li>
                            <li>Impaired Growth</li>
                            <li>Vitamin Deficiency</li>
                            <li>Loss of Appetite</li>
                        </ul>
                    </div>
            
                    <p><strong>NUTRIGET™-L:</strong> The Complete Health Formula</p>
                </div>
            </div>

        </div>
    </div>

    <!--<div class="related-products-area mt-20">
        <div class="container">
            <div class="section-title">
                <span>Our Products</span>
                <h2>Related Products</h2>
                <p>Sanjivani Healthcare offers premium health solutions with a focus on quality and effectiveness, ensuring comprehensive care for your needs.</p>
            </div>

            <div class="row">
               
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithi Joint</a></h3>
                           <p>Lithi Joint is a specialized supplement formulated to support joint health and flexibility. Enriched with natural ingredients, it helps reduce discomfort and improve mobility, promoting overall joint well-being and comfort.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithicoxib MR</a></h3>
                           <p>Lithicoxib MR provides effective relief from chronic pain and inflammation with its extended-release formula, ensuring consistent comfort and improved quality of life for long-term management.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section>
