import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-home',
  templateUrl: './home1.component.html',
  styleUrls: ['./home1.component.scss']
})
export class Home1Component implements OnInit {
  appointmentForm: FormGroup;
  banner = {
    heading: 'Empowering Health with Innovative Solutions',
    description: 'Moonray Pharmaceuticals Pvt Ltd., a Sanjivani Healthcare unit, pioneers innovative therapies, improving patient outcomes with cutting-edge research and excellence.',
    buttonText: 'Contact Us',
    buttonLink: '/contact',
    heading2: 'Leading the Way in Advanced Health Solutions',
    description2: 'Moonray Pharmaceuticals Pvt Ltd., a Sanjivani Healthcare unit, pioneers innovative therapies, improving patient outcomes with cutting-edge research and excellence.',
    buttonText2: 'Contact Us',
    buttonLink2: '/contact',
    heading3: 'Transforming Healthcare Through Cutting-Edge Innovation',
    description3: 'Moonray Pharmaceuticals Pvt Ltd., a Sanjivani Healthcare unit, pioneers innovative therapies, improving patient outcomes with cutting-edge research and excellence.',
    buttonText3: 'Contact Us',
    buttonLink3: '/contact',
    images: {
      mainImage: 'assets/img/01.png',
      backgroundImage: 'assets/img/bg-shape2.png',
     },
     images2: {
      mainImage: 'assets/img/Pharmacy-02.png',
      backgroundImage: 'assets/img/bg-shape2.png',
     },
     images3: {
      mainImage: 'assets/img/03.png',
      backgroundImage: 'assets/img/bg-shape2.png',
     }
  };

  boxes = [
    {
      icon: 'flaticon-laboratory',
      heading: 'Innovative Therapies',
      description: 'Leading the way in advanced medical solutions for better patient care.',
      link: '/home',
      shapeImages: [
        'assets/img/shape-image/9.png',
        'assets/img/shape-image/10.png'
      ]
    },
    {
      icon: 'flaticon-microscope',
      heading: 'Cutting-edge Research',
      description: 'Empowering breakthroughs through relentless scientific exploration.',
      link: '/home',
      shapeImages: [
        'assets/img/shape-image/9.png',
        'assets/img/shape-image/10.png'
      ],
      bgClass: 'bg-43c784'
    },
    {
      icon: 'flaticon-laboratory-1',
      heading: 'Excellence in Outcomes',
      description: 'Transforming lives with superior healthcare advancements.',
      link: '/home',
      shapeImages: [
        'assets/img/shape-image/9.png',
        'assets/img/shape-image/10.png'
      ],
      bgClass: 'bg-f59f00'
    }
  ];

  aboutContent = {
    image: 'assets/img/about-img1.png',
    title: 'Discoveries That Deliver Your Solutions',
    description: 'At Moonray Pharmaceuticals Pvt Ltd., a proud unit of Sanjivani Healthcare, we are dedicated to pioneering groundbreaking treatments and innovative solutions to meet your healthcare needs. Our mission is to transform lives through the development of effective therapies and improved patient outcomes. Our team of experts works tirelessly, leveraging cutting-edge research and advanced technologies to push the boundaries of medical science.',
    features: [
      'Chemical Research',
      'Pathology Testing',
      'Sample Preparation',
      'Healthcare Labs',
      'Advanced Microscopy',
      'Advanced Robotics',
      'Environmental Testing',
      'Anatomical Pathology'
    ],
    learnMoreLink: '/about-us',
    videoLink: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
    bgShape: 'assets/img/bg-shape1.png',
    shapeImg: 'assets/img/shape-image/4.png'
  };

  productsectionTitle = {
    span: 'Products',
    h2: 'There is novcc Doubt To Get Your Products Over Year of Experience',
    link: '/products',
    linkText: 'Tell Us More'
  };

  products = [
    {
      image: 'assets/img/Sanjivani Healthcare-01.jpg',
      title: 'Nutriget-9Gxxx',
      description: 'Omega-3 Fatty Acid, Green Tea Extract, Ginkgo Biloba Extract, Ginseng, Grape Seed Extract, Ginger Root Extract, Green Coffee Extract, Guggul Extract, Garlic Powder, Glycyrrhiza Glabra Extract, L-Carnitine, Multivitamins, Multiminerals & Antioxidant Softgel Capsules',
      link: '/nutriget-9-g'
    },
    {
      image: 'assets/img/Sanjivani-Healthcare-02.jpg',
      title: 'Lithi Joint',
      description: 'Cissus Quadrangularis Linn Extract 500 mg, Tricalcium Phosphate 375 mg eq. to Elemental Calcium 145 mg eq. to Elemental Phosphorus 74 mg, Withania Somnifera Extract 50 mg, Ergocalciferol (Vitamin D2) 130 IU, Vitamin K2-7 45 mcg Tablets',
      link: '/lithi-joint'
    },
    {
      image: 'assets/img/Sanjivani-Healthcare-03.jpg',
      title: 'Lithicoxib MR',
      description: 'Terminate the Spasm Effectively with Lithicoxib MR - Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablets.Skeletal Muscle Spasm, Sprain & Strain, Post-Operative Spasm & Pain, Acute/Chronic Low Back Pain.Potent, Faster & Prolonged Action',
      link: '/lithicoxib-mr'
    },
    {
      image: 'assets/img/Sanjivani-Healthcare-03.jpg',
      title: 'Lithicoxib MR',
      description: 'Terminate the Spasm Effectively with Lithicoxib MR - Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablets.Skeletal Muscle Spasm, Sprain & Strain, Post-Operative Spasm & Pain, Acute/Chronic Low Back Pain.Potent, Faster & Prolonged Action',
      link: '/lithicoxib-mr'
    },
    {
      image: 'assets/img/Sanjivani-Healthcare-03.jpg',
      title: 'Lithicoxib MR',
      description: 'Terminate the Spasm Effectively with Lithicoxib MR - Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablets.Skeletal Muscle Spasm, Sprain & Strain, Post-Operative Spasm & Pain, Acute/Chronic Low Back Pain.Potent, Faster & Prolonged Action',
      link: '/lithicoxib-mr'
    },
    {
      image: 'assets/img/Sanjivani-Healthcare-03.jpg',
      title: 'Lithicoxib MR',
      description: 'Terminate the Spasm Effectively with Lithicoxib MR - Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablets.Skeletal Muscle Spasm, Sprain & Strain, Post-Operative Spasm & Pain, Acute/Chronic Low Back Pain.Potent, Faster & Prolonged Action',
      link: '/lithicoxib-mr'
    }
   ];

  testimonialsectionTitle = {
    span: 'Testimonial',
    title: 'What’s Clients Response About'
  };

  blogsectionTitle = {
    span: 'News Update',
    title: 'News & Blog',
    description: 'Stay updated with the latest healthcare advancements, research breakthroughs, and company news from Moonray Pharmaceuticals. Explore insightful articles and expert opinions.'
  };

  blogPosts = [
    {
      date: 'Nutriget-9G',
      author: 'Akash Sharma',
      title: 'Nutriget-9G: Revolutionizing Nutrition for a Healthier Future',
      excerpt: 'Discover Nutriget-9G, a cutting-edge supplement offering essential nutrients to boost energy and enhance wellness. Embrace a healthier lifestyle with our advanced formula designed for optimal health and vitality.',
      imageUrl: 'assets/img/blog-img9.jpg',
      link: '/products'
    },
    {
      date: 'Lithi Joint',
      author: 'Mohit Kummar',
      title: 'Lithi Joint: Advanced Joint Support for Active Living',
      excerpt: 'Explore Lithi Joint, a revolutionary supplement designed to support joint health, reduce discomfort, and enhance mobility. Achieve a more active and pain-free lifestyle with our scientifically formulated solution.',
      imageUrl: 'assets/img/blog-2.png',
      link: '/products'
    },
    {
      date: 'Lithicoxib MR',
      author: 'Akash Sharma',
      title: 'Lithicoxib MR: Enhanced Relief for Chronic Pain',
      excerpt: 'Discover Lithicoxib MR, an advanced medication offering long-lasting relief from chronic pain and inflammation. It targets discomfort at its source, helping you regain mobility and improve your quality of life.',
      imageUrl: 'assets/img/blog-3.png',
      link: '/products'
    }
  ];

  testimonials = [
    {
      message: 'Moonray Pharmaceuticals has been a game-changer for my health. Their innovative treatments are effective and reliable.',
      name: 'Sarah M.',
    },
    {
      message: 'Exceptional service and cutting-edge solutions. Moonray commitment to excellence is evident in every product.',
      name: 'Dr. John P.',
     },
    {
      message: 'Moonray Pharmaceutical groundbreaking therapies have significantly enhanced my quality of life. Thank you for your dedication!',
      name: 'Emily R.',
    },
    {
      message: 'Outstanding care and innovative solutions. Moonray Pharmaceuticals Pharmaceutical truly makes a difference in healthcare.',
      name: 'Michael L.',
     
    },
    {
      message: 'Moonray Pharmaceuticals of Sanjivani Healthcare advanced therapies have provided me with remarkable health improvements.',
      name: 'Sarah Taylor',
    }
  ];

  constructor(
    private meta: Meta,
    private title: Title,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Welcome to Moonray Pharmaceutical: Innovating Healthcare, Transforming Lives');
    this.meta.updateTag({ name: 'description', content: 'Moonray Pharmaceutical is dedicated to advancing healthcare through innovative research and development. Discover our cutting-edge treatments and solutions designed to improve patient outcomes.' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:title', content: 'Moonray Pharmaceutical - Innovating Healthcare' });
    this.meta.updateTag({ property: 'og:description', content: 'Explore Moonray Pharmaceutical’s pioneering research and development in healthcare. Our mission is to enhance patient care with innovative solutions.' });
    this.meta.updateTag({ property: 'og:url', content: '' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Moonray Pharmaceutical' });
    this.meta.updateTag({ property: 'og:image', content: '' });
    this.meta.updateTag({ rel: 'canonical', href: '' });

    this.appointmentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });

    this.loadDynamicContent();
  }

  loadDynamicContent(): void {
    // Load dynamic content if needed (e.g., from an API or service)
  }

  onSubmit(): void {
    if (this.appointmentForm.valid) {
      console.log(this.appointmentForm.value);
      this.toastr.success('Your appointment request has been submitted successfully!', 'Success');
      this.appointmentForm.reset();
    } else {
      this.toastr.error('Please fill in the required fields correctly.', 'Error');
      console.log('Form is invalid');
    }
  }
}


