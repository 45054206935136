import { Component } from '@angular/core';

@Component({
  selector: 'app-nutriget-l',
  standalone: true,
  imports: [],
  templateUrl: './nutriget-l.component.html',
  styleUrl: './nutriget-l.component.scss'
})
export class NutrigetLComponent {

}
