<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Analytical Balances</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Products Details</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1 rotateme"><img src="assets/img/shape-image/1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape-image/2.png" alt="image"></div>
    <div class="shape-img3 rotateme"><img src="assets/img/shape-image/3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape-image/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape-image/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape-image/6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape-image/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape-image/8.png" alt="image"></div>
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="product-details-image">
                    <img src="assets/img/shop-img1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="product-details-desc">
                    <h3>Analytical Balances</h3>
                   
                   
                    <p>Design inspiration lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar odio lorem non turpis. Nullam sit amet enim. Suspendisse id velit vitae ligula volutpat condimentum. Aliquam erat volutpat. Sed quis velit. Nulla facilisi. Nulla libero. Vivamus pharetra posuere sapien. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nulla libero. Vivamus pharetra posuere sapien.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et. I agree with the terms and conditions. I agree with the terms and conditions. I agree with the terms and conditions.</p>
                  
                 
                   
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <div class="dot"></div> Description
                        </a></li>
                        <li><a href="#">
                            <div class="dot"></div> Additional information
                        </a></li>
                      
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <p>Design inspiration lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar odio lorem non turpis. Nullam sit amet enim. Suspendisse id velit vitae ligula volutpat condimentum.  Aliquam erat volutpat. Sed quis velit. Nulla facilisi. Nulla libero. Vivamus pharetra posuere sapien. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nulla libero. Vivamus pharetra posuere sapien.</p>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <ul class="additional-information">
                                    <li><span>Brand:</span> ThemeForest</li>
                                    <li><span>Color:</span> Brown</li>
                                    <li><span>Size:</span> Large, Medium</li>
                                    <li><span>Weight:</span> 27 kg</li>
                                    <li><span>Dimensions:</span> 16 x 22 x 123 cm</li>
                                </ul>
                            </div>
                        </div>

                   
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="related-products-area">
        <div class="container">
            <div class="section-title">
                <span>Our Products</span>
                <h2>Related Products</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Dummy Heading</a></h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            <a routerLink="/products-details" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Dummy Heading</a></h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            <a routerLink="/products-details" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Dummy Heading</a></h3>
                           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                            <a routerLink="/products-details" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>