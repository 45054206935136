<div class="page-title-area products_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">Nutriget D3 Drops</h2>
            <ul>
                <li><a href="/products">Products</a></li>
                <li>Nutriget D3 Drops Details</li>
            </ul>
        </div>
    </div>
  
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image"><a><img
                                alt="image" src="assets/img/products/1.png"></a>
                        <div class="date">Nutriget D3 Drops</div>
                    </div>
                   
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="product-details-desc about-content">
                    <h3>NUTRIGET™-D3</h3>
                    <div class="highlight">
                        <h5>Natural Sources of Vitamin D3</h5>
                    </div>
            
                    <p><strong>Cholecalciferol (Vitamin D3):</strong> 800 I.U. Drops</p>
            
                    <div class="section-heading">Benefits</div>
                    <ul>
                        <li>Regulates the immune system</li>
                        <li>Maintains healthy nerves and muscles</li>
                        <li>Stimulates the body to absorb calcium for strong bones & teeth</li>
                        <li>Promotes healthy growth & development in infancy & childhood</li>
                    </ul>
            
                    <div class="section-heading">Indicated For</div>
                    <ul>
                        <li>Impaired Growth</li>
                        <li>Vitamin D3 Deficiency</li>
                        <li>Rickets</li>
                    </ul>
            
                    <p><strong>NUTRIGET™-D3:</strong> Raises Healthy & Strong Babies</p>
                   
                </div>
            </div>

        </div>
    </div>

    <!--<div class="related-products-area mt-20">
        <div class="container">
            <div class="section-title">
                <span>Our Products</span>
                <h2>Related Products</h2>
                <p>Sanjivani Healthcare offers premium health solutions with a focus on quality and effectiveness, ensuring comprehensive care for your needs.</p>
            </div>

            <div class="row">
               
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithi Joint</a></h3>
                           <p>Lithi Joint is a specialized supplement formulated to support joint health and flexibility. Enriched with natural ingredients, it helps reduce discomfort and improve mobility, promoting overall joint well-being and comfort.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithicoxib MR</a></h3>
                           <p>Lithicoxib MR provides effective relief from chronic pain and inflammation with its extended-release formula, ensuring consistent comfort and improved quality of life for long-term management.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section>
