import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutriget-9-g',
  templateUrl: './nutriget-9-g.component.html',
  styleUrl: './nutriget-9-g.component.scss'
})
export class Nutriget9GComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
