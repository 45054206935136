import { Component } from '@angular/core';

@Component({
  selector: 'app-lithicoxib-mr',
  standalone: true,
  imports: [],
  templateUrl: './lithicoxib-mr.component.html',
  styleUrl: './lithicoxib-mr.component.scss'
})
export class LithicoxibMRComponent {

}
