import { Component } from '@angular/core';

@Component({
  selector: 'app-lithi-joint',
  standalone: true,
  imports: [],
  templateUrl: './lithi-joint.component.html',
  styleUrl: './lithi-joint.component.scss'
})
export class LithiJointComponent {

}
