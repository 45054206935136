import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Required for animations
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeComponent } from './components/pages/home/HomeComponent';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { AppointmentComponent } from './components/common/appointment/appointment.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Home1Component } from './components/pages/home1/Home1Component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    ComingSoonComponent,
    FooterComponent,
    Home1Component,
    HomeComponent,
    AboutUsComponent,
    PartnerComponent,
    FunfactsComponent,
    ErrorComponent,
    ProductsComponent,
    AppointmentComponent,
    ProductsDetailsComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
     HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right', // Position of the toast
      timeOut: 3000, // Duration in milliseconds (3 seconds)
      preventDuplicates: true,
      progressBar: true, // Optional: show a progress bar
    }),
  ],
  providers: [
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
