<section class="coming-soon-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="coming-soon-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div  class="logo"><a routerLink="/home" class="coming-soon-logo"><img src="assets/img/logo.png" with="100"></a></div>
                            <h3>Coming Soon!</h3>
                          <p>
                            Exciting News! Moonray Pharmaceutical is on the brink of unveiling groundbreaking healthcare solutions. Stay tuned as we launch innovative treatments aimed at enhancing patient care worldwide. Coming soon!
                        </p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="coming-soon-time">
                    <img src="assets/img/coming-soon-bg.jpg" alt="image">
                   
                </div>
            </div>
        </div>
    </div>
</section>