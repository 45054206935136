<div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active"
            aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3"
            aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="4"
            aria-label="Slide 5"></button>


    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="assets/img/bn_1.png" class="d-block w-100 d-none d-md-block" alt="...">
            <img src="assets/img/products/10.png" class="d-block w-100  d-md-none" alt="...">

            <div class="carousel-content">
                <div class="carousel-caption-left main-banner-content mt-5">
                    <h1>Empowering Health Innovation</h1>
                    <p>Moonray Pharmaceuticals Pvt Ltd., A unit of the Sanjivani Healthcare, pioneers innovative therapies,
                        improving patient outcomes with cutting-edge research and excellence.</p>
                    <a class="add-to-cart-btn btn btn-secondary mt-4" routerLink="/about-us">Read More</a>
                </div>
            </div>
        </div>

        <div class="carousel-item">

            <!-- Image for desktop only -->
            <img src="assets/img/bn6.png" class="d-block w-100 d-none d-md-block" alt="...">

            <!-- Image for mobile only -->
            <img src="assets/img/01.png" class="d-block w-100 pt-5 d-md-none" alt="...">

            <div class="carousel-content">
                <div class="carousel-caption-left main-banner-content mt-5">
                    <h1>Advanced Health Pioneers</h1>
                    <p>Moonray Pharmaceuticals Pvt Ltd., A unit of Sanjivani Healthcare, leads the way in developing trustable treatments, enhancing patient care through advanced research and a commitment to excellence.</p>
                    <a class="add-to-cart-btn btn btn-secondary mt-4" routerLink="/about-us">Read More</a>
                </div>
            </div>
        </div>



        <div class="carousel-item">

            <!-- Image for desktop only -->
            <img src="assets/img/bn5.png" class="d-block w-100  d-none d-md-block" alt="...">

            <!-- Image for mobile only -->
            <img src="assets/img/Pharmacy 02.png" class="d-block w-100 pt-5 d-md-none" alt="...">

            <div class="carousel-content">
                <div class="carousel-caption-left main-banner-content mt-5">
                    <h1>Dedicated to Quality & Safety</h1>
                    <p>Moonray Pharmaceuticals Pvt Ltd., a Sanjivani Healthcare unit, pioneers innovative therapies,
                        improving patient outcomes with cutting-edge research and excellence.</p>
                    <a class="add-to-cart-btn btn btn-secondary mt-4" routerLink="/about-us">Read More</a>
                </div>
            </div>
        </div>

        <div class="carousel-item">

            <!-- Image for desktop only -->
            <img src="assets/img/banner1.jpg" class="d-block w-100  d-none d-md-block" alt="...">
             <!-- Image for mobile only -->
             <img src="assets/img/products/01.png" class="d-block w-100  d-md-none" alt="...">

       </div>

        <div class="carousel-item">

            <!-- Image for desktop only -->
            <img src="assets/img/banner2.jpg" class="d-block w-100  d-none d-md-block" alt="...">
            <img src="assets/img/products/02.png" class="d-block w-100  d-md-none" alt="...">
         </div>






    </div>

</div>
<!-- home.component.html -->

<section class="banner_bottom_section pt-3 pb-3" style="background-color: red;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
           
                <h2 class="text-white text-center hs">Moonray Pharmaceuticals Pvt Ltd., A unit of the Sanjivani Healthcare</h2>
                <div class="btn-box">

                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img [src]="aboutContent.image" alt="About Us Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>{{ aboutContent.title }}</h2>
                    <p>{{ aboutContent.description }}</p>
                    <ul class="about-features-list">
                        <li *ngFor="let feature of aboutContent.features">
                            <i class="flaticon-check-mark"></i> {{ feature }}
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a [routerLink]="aboutContent.learnMoreLink" class="btn btn-primary">Learn More</a>

                    </div>
                </div>
            </div>
        </div>
    </div>


</section>

<!-- products.component.html -->
<section class="services-area ptb-120 pt-0">
    <div class="container">
        <div class="section-title">
            <span>{{ productsectionTitle.span }}</span>
            <h2>{{ productsectionTitle.h2 }}</h2>
            <a [routerLink]="productsectionTitle.link" class="btn btn-secondary">{{ productsectionTitle.linkText }}</a>
        </div>

        <div class="row">
            <div *ngFor="let product of products" class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image">
                        <a [routerLink]="product.link">
                            <img [src]="product.image" alt="Product Image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h3><a [routerLink]="product.link">{{ product.title }}</a></h3>
                        <p [innerHTML]="product.description"></p>
                        <a [routerLink]="product.link" class="add-to-cart-btn btn btn-secondary">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--appointment section-->
<app-appointment></app-appointment>
<!--appointment section-->

<div class="partner-area bg-fff7f4 extra-pt">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>
<!-- home.component.html -->
<section class="testimonial-area ptb-120 pt-120">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">{{ testimonialsectionTitle.span }}</span>
            <h2>{{ testimonialsectionTitle.title }}</h2>
        </div>
    </div>

    <div class="testimonial-slides owl-carousel owl-theme">
        <div class="single-testimonial-item" *ngFor="let testimonial of testimonials">
            <p>"{{ testimonial.message }}"</p>
            <div class="client-info">
                <h3>{{ testimonial.name }}</h3>

            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>
    </div>
</section>

<!-- home.component.html -->
<section class="blog-area ptb-120 bg-fff7f4">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">{{ blogsectionTitle.span }}</span>
            <h2>{{ blogsectionTitle.title }}</h2>
            <p>{{ blogsectionTitle.description }}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blogPost of blogPosts">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a [routerLink]="blogPost.link"><img [src]="blogPost.imageUrl" alt="image"></a>
                        <div class="date">{{ blogPost.date }}</div>
                    </div>
                    <div class="post-content">
                        <span>By: <a [routerLink]="blogPost.link">{{ blogPost.author }}</a></span>
                        <h3><a [routerLink]="blogPost.link">{{ blogPost.title }}</a></h3>
                        <p>{{ blogPost.excerpt }}</p>
                        <a [routerLink]="blogPost.link" class="learn-more-btn">Learn More <i
                                class="flaticon-arrow-pointing-to-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

