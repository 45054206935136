<footer class="footer-area bg-fffcfb">
    <div class="container">
      <div class="row">
        <!-- Company Info -->
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <div class="logo">
              <a class="navbar-brand" routerLink="/home"><img src="assets/img/logo.png" width="200"></a>
              <p>{{ companyInfo.description }}</p>
            </div>
          </div>
        </div>
  
        <!-- Useful Links -->
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5 ml-3">
            <h3>Useful Links</h3>
            <ul class="useful-links-list">
              <li *ngFor="let link of usefulLinks">
                <a routerLink="{{ link.link }}">{{ link.text }}</a>
              </li>
            </ul>
          </div>
        </div>
  
       <!-- Contact Info -->
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Contact Info</h3>
            <ul class="footer-contact-info">
              <li><i class="flaticon-facebook-placeholder-for-locate-places-on-maps"></i>{{ companyInfo.address }}</li>
              <li><i class="flaticon-phone"></i> <a href="tel:{{ companyInfo.phone }}">{{ companyInfo.phone }}</a></li>
              <li><i class="flaticon-close-envelope"></i> <a href="mailto:{{ companyInfo.email }}">{{ companyInfo.email }}</a></li>
              <li><i class="flaticon-internet"></i> <a routerLink="/home">{{ companyInfo.website }}</a></li>
            </ul>
          </div>
        </div>
      </div>
  
      <!-- Copyright Area -->
      <div class="copyright-area">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-6 col-sm-8">
            <p>© {{ copyright.year }} <a [href]="copyright.companyLink" target="_blank">{{ copyright.company }}</a>. {{ copyright.text }}</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <ul class="social-links">
              <li *ngFor="let link of companyInfo.socialLinks">
                <a [href]="link.link" target="_blank"><i [class]="link.icon"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <a href="tel:+91 9721388800" class="float2"><i class="fa fa-phone my-float2"></i></a>
  
  <a href="https://api.whatsapp.com/send?phone=+919721388800&amp;text=Thank you for contacting Moonray Pharmaceutical! Please let us know how we can help you."
    target="_blank" class="float"><img src="assets/img/products/WhatsApp_icon.png.webp" width="35"></a>

   
  