<section class="appointment-area" [ngStyle]="{ 'background-image': 'url(' + backgroundImageUrl + ')' }">
    <div class="container">
      <div class="section-title text-center">
        <span class="bg-f59f00">{{ sectionSubtitle }}</span>
        <h2>{{ sectionTitle }}</h2>
        <p>{{ sectionDescription }}</p>
      </div>
    </div>
  
    <div class="appointment-inner-area jarallax" data-jarallax='{"speed": 0.3}'>
      <div class="container">
        <div class="appointment-form">
          <h3>Fill up the form</h3>
          <form [formGroup]="appointmentForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <input type="text" class="form-control" id="name" formControlName="name" placeholder="Name*" />
              <div *ngIf="appointmentForm.get('name').invalid && (appointmentForm.get('name').dirty || appointmentForm.get('name').touched)">
                <small class="text-danger">Name is required.</small>
              </div>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email*" />
              <div *ngIf="appointmentForm.get('email').invalid && (appointmentForm.get('email').dirty || appointmentForm.get('email').touched)">
                <small class="text-danger">Valid email is required.</small>
              </div>
            </div>
            <div class="form-group">
              <textarea name="message" id="message" cols="30" rows="6" class="form-control" formControlName="message" placeholder="Message"></textarea>
            </div>
            <button type="submit" class="btn btn-primary">{{ formButtonText }}</button>
          </form>
        </div>
      </div>
    </div>
  </section>

  