<div class="row">
    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="1050">00</span></h3>
            <p>Stars Comfort</p>
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="302">00</span></h3>
            <p>Professional Staff</p>
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="50">00</span></h3>
            <p>Years Of Experience</p>
        </div>
    </div>

    <div class="col-lg-3 col-md-3 col-sm-3 col-6">
        <div class="single-funfacts">
            <h3><span class="odometer" data-count="640">00</span></h3>
            <p>Suppliers</p>
        </div>
    </div>
</div>