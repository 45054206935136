<div class="page-title-area contact_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">Contact Us</h2>
            <ul>
                <li><a routerLink="/home">Home</a></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
   
</div>

<section class="contact-area ptb-120">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">Message Us</span>
            <h2>Drop us message for any query</h2>
            <p>Drop us a message for any query or concern. Our team at Moonray Pharmaceuticals is here to assist you with prompt and professional support. Feel free to reach out!</p>
        </div>

        <div class="contact-form-area">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Name" formControlName="name">
                    <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                      <small class="text-danger">Name is required.</small>
                    </div>
                  </div>
                </div>
          
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Your Email Address" formControlName="email">
                    <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                      <small class="text-danger">Valid email is required.</small>
                    </div>
                  </div>
                </div>
          
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Phone Number" formControlName="phone_number">
                    <div *ngIf="contactForm.get('phone_number').invalid && (contactForm.get('phone_number').dirty || contactForm.get('phone_number').touched)">
                      <small class="text-danger">Phone number is required.</small>
                    </div>
                  </div>
                </div>
          
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Subject" formControlName="msg_subject">
                    <div *ngIf="contactForm.get('msg_subject').invalid && (contactForm.get('msg_subject').dirty || contactForm.get('msg_subject').touched)">
                      <small class="text-danger">Subject is required.</small>
                    </div>
                  </div>
                </div>
          
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea class="form-control" placeholder="Type Your Message Here" formControlName="message" cols="30" rows="10"></textarea>
                    <div *ngIf="contactForm.get('message').invalid && (contactForm.get('message').dirty || contactForm.get('message').touched)">
                      <small class="text-danger">Message is required.</small>
                    </div>
                  </div>
                </div>
          
                <div class="col-lg-12 col-md-12 text-center">
                  <button type="submit" class="btn btn-primary">Send Message</button>
                </div>
              </div>
            </form>
          </div>
          

        <div class="contact-info">
            <div class="section-title text-center">
                <h2>Don't Hesitate to contact with us</h2>
                <p>Need assistance? The Moonray Pharmaceuticals team is ready to provide you speedy professional support. Don’t hesitate to reach out—we’re here to help!</p>
            </div>

            <div class="contact-info-content">
                <h3>Call us for imidiate support to this number</h3>
                <h2><a href="tel:+91 9721388800">+91 9721388800</a></h2>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div id="map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.948420178355!2d78.99169317526005!3d21.154450880526504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4eb006a8eaa8b%3A0xe11a41dd473de44f!2sMalti%20Appartment%2C%20Adivasi%20society%2C%20Naka%2010%20Wadi!5e0!3m2!1sen!2sin!4v1722334699138!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<div class="toast-message" *ngIf="showToast">
    <p>{{ toastMessage }}</p>
  </div>

