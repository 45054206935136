import { Component } from '@angular/core';

@Component({
  selector: 'app-nutriget-d',
  standalone: true,
  imports: [],
  templateUrl: './nutriget-d.component.html',
  styleUrl: './nutriget-d.component.scss'
})
export class NutrigetDComponent {

}
