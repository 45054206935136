import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navItems: { label: string, link: string }[] = [];

  constructor() { }

  ngOnInit(): void {
    // Initialize the navigation items
    this.navItems = [
      { label: 'Home', link: '/home' },
      { label: 'About Us', link: '/about-us' },
      { label: 'Products', link: '/products' },
      { label: 'Contact Us', link: '/contact' }
    ];
  }
}
