<!-- navbar.component.html -->
<div class="navbar-area">
    <div class="zylon-mobile-nav mean-container">
        <div class="mean-bar">
            <a href="#nav" class="meanmenu-reveal">
                <span><span><span></span></span></span>
            </a>
        </div>
        <div class="logo">
            <a routerLink="/home"><img src="assets/img/logo.png" width="140"></a>
        </div>
    </div>

    <div class="zylon-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/home"><img src="assets/img/logo.png" with="100"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li *ngFor="let item of navItems" class="nav-item">
                            <a [routerLink]="item.link" class="nav-link">{{ item.label }}</a>
                        </li>
                    </ul>

                    <div class="others-options">
                        <a href="tel:+91 9721388800" class="btn btn-secondary"><i class="flaticon-phone"></i> +91 9721388800</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
