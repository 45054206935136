<div class="page-title-area products_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">PRODUCTS</h2>
            <ul>
                <li><a routerLink="/home">Home</a></li>
                <li>Products</li>
            </ul>
        </div>
    </div>
   
</div>

<section class="shop-area ptb-120">
    <div class="container">
        <div class="row">
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image"><a
                            routerLink="/nutriget-9-g"><img alt="Product Image"
                                src="assets/img/blog-img9.jpg"></a></div>
                    <div class="product-content">
                        <h3><a routerLink="/nutriget-9-g">Nutriget-9G</a></h3>
                        <p>Omega-3 Fatty Acid, Green Tea Extract, Ginkgo Biloba Extract, Ginseng,
                            Grape Seed Extract, Ginger Root Extract, Green Coffee Extract, Guggul Extract, Garlic Powder,
                            Glycyrrhiza Glabra Extract, L-Carnitine, Multivitamins, Multiminerals &amp; Antioxidant Softgel
                            Capsules</p><a class="add-to-cart-btn btn btn-secondary"
                            routerLink="/nutriget-9-g">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image"><a
                            routerLink="/lithi-joint"><img alt="Product Image"
                                src="assets/img/blog-2.png"></a></div>
                    <div class="product-content">
                        <h3><a routerLink="/lithi-joint">Lithi Joint</a></h3>
                        <p>Cissus Quadrangularis Linn Extract 500 mg, Tricalcium Phosphate 375 mg
                            eq. to Elemental Calcium 145 mg eq. to Elemental Phosphorus 74 mg, Withania Somnifera Extract 50 mg,
                            Ergocalciferol (Vitamin D2) 130 IU, Vitamin K2-7 45 mcg Tablets</p><a
                            class="add-to-cart-btn btn btn-secondary" routerLink="/lithi-joint">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image"><a
                            routerLink="/lithicoxib-mr"><img alt="Product Image"
                                src="assets/img/blog-3.png"></a></div>
                    <div class="product-content">
                        <h3><a routerLink="/lithicoxib-mr">Lithicoxib MR</a>
                        </h3>
                        <p>Terminate the Spasm Effectively with Lithicoxib MR - Etoricoxib 60 mg +
                            Thiocolchicoside 4 mg Tablets.Skeletal Muscle Spasm, Sprain &amp; Strain, Post-Operative Spasm &amp;
                            Pain, Acute/Chronic Low Back Pain.Potent, Faster &amp; Prolonged Action</p><a
                            class="add-to-cart-btn btn btn-secondary" routerLink="/lithicoxib-mr">Read
                            More</a>
                    </div>
                </div>
            </div>
            
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image"><a
                            routerLink="/nutriget-d"><img alt="Product Image"
                                src="assets/img/products/1.png"></a></div>
                    <div class="product-content">
                        <h3><a routerLink="/nutriget-d">Nutriget D3 Drops</a>
                        </h3>
                        <p>Nutriget D3 Drops is an oral preparation focused on vitamin D3, specifically Cholecalciferol (Vitamin D3) at 800 IU. It supports bone tissue, boosts immunity, and enhances overall health. Packed with essential vitamins and natural constituents, Nutriget D3 promotes vitality and well-being.

                            </p><a
                            class="add-to-cart-btn btn btn-secondary" routerLink="/nutriget-d">Read
                            More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image"><a
                            routerLink="/lithicoxib90"><img alt="Product Image"
                                src="assets/img/products/2.png"></a></div>
                    <div class="product-content">
                        <h3><a routerLink="/lithicoxib90">Lithicoxib 90</a>
                        </h3>
                        <p>Lithicoxib 90 contains etoricoxib, a powerful NSAID tablet specially designed for fast, effective pain relief and inflammation control. It is used for various conditions like arthritis, offering long-lasting symptom management and improving overall joint mobility and flexibility.</p><a
                            class="add-to-cart-btn btn btn-secondary" routerLink="/lithicoxib90">Read
                            More</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="single-product-box">
                    <div class="product-image"><a
                            routerLink="/nutriget-l"><img alt="Product Image"
                                src="assets/img/products/3.png"></a></div>
                    <div class="product-content">
                        <h3><a routerLink="/nutriget-l">Nutriget-L Drops</a>
                        </h3>
                        <p>Nutriget-L Drops is a dietary supplement rich in essential vitamins and minerals, including <b>flaxseed oil, a rich source of omega-3 fatty acids,</b> that supports overall health. It aids in immune function, energy levels, and general wellness with its balanced nutrient formula.</p><a
                            class="add-to-cart-btn btn btn-secondary" routerLink="/nutriget-l">Read
                            More</a>
                    </div>
                </div>
            </div><!---->
        </div>
    </div>
</section>