import { Component } from '@angular/core';

@Component({
  selector: 'app-lithicoxib90',
  standalone: true,
  imports: [],
  templateUrl: './lithicoxib90.component.html',
  styleUrl: './lithicoxib90.component.scss'
})
export class Lithicoxib90Component {

}
