<section class="main-banner">
  
    <div class="banner-slides owl-carousel owl-theme">
        <div class="item">
            <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <!--<span>Pharmaceuticile</span>-->
                        <h1>{{ banner.heading }}</h1>
                        <p>{{ banner.description }}</p>
                        <a [routerLink]="banner.buttonLink" class="btn btn-primary">{{ banner.buttonText }}</a>
                    </div>
                </div>
    
                <div class="col-lg-6 col-md-12">
                    <div class="banner-image">
                        <img [src]="banner.images.mainImage" alt="image">
                        <img [src]="banner.images.backgroundImage" alt="image">
                    </div>
                </div>
            </div>
           </div>
           <div class="shape-img1 rotateme"><img src="assets/img/shape-image/1.png" alt="image"></div>
           <div class="shape-img2"><img src="assets/img/shape-image/2.png" alt="image"></div>
           <div class="shape-img3 rotateme"><img src="assets/img/shape-image/3.png" alt="image"></div>
           <div class="shape-img4"><img src="assets/img/shape-image/4.png" alt="image"></div>
           <div class="shape-img5"><img src="assets/img/shape-image/5.png" alt="image"></div>
           <div class="shape-img6"><img src="assets/img/shape-image/6.png" alt="image"></div>
           <div class="shape-img7"><img src="assets/img/shape-image/7.png" alt="image"></div>
           <div class="shape-img8"><img src="assets/img/shape-image/8.png" alt="image"></div>
        </div>
        <div class="item">
            <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 order-2 order-md-1">
                    <div class="banner-image">
                        <img [src]="banner.images2.mainImage" alt="image">
                        <img [src]="banner.images2.backgroundImage" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 order-1 order-md-2">
                    <div class="main-banner-content">
                        <!--<span>Pharmaceuticile</span>-->
                        <h1>{{ banner.heading2 }}</h1>
                        <p>{{ banner.description2 }}</p>
                        <a [routerLink]="banner.buttonLink2" class="btn btn-primary">{{ banner.buttonText2 }}</a>
                    </div>
                </div>
    
               
            </div>
           </div>
           <div class="shape-img1 rotateme"><img src="assets/img/shape-image/1.png" alt="image"></div>
           <div class="shape-img2"><img src="assets/img/shape-image/2.png" alt="image"></div>
           <div class="shape-img3 rotateme"><img src="assets/img/shape-image/3.png" alt="image"></div>
           <div class="shape-img4"><img src="assets/img/shape-image/4.png" alt="image"></div>
           <div class="shape-img5"><img src="assets/img/shape-image/5.png" alt="image"></div>
           <div class="shape-img6"><img src="assets/img/shape-image/6.png" alt="image"></div>
           <div class="shape-img7"><img src="assets/img/shape-image/7.png" alt="image"></div>
           <div class="shape-img8"><img src="assets/img/shape-image/8.png" alt="image"></div>
        </div>

        <div class="item">
            <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <!--<span>Pharmaceuticile</span>-->
                        <h1>{{ banner.heading3 }}</h1>
                        <p>{{ banner.description3 }}</p>
                        <a [routerLink]="banner.buttonLink" class="btn btn-primary">{{ banner.buttonText3 }}</a>
                    </div>
                </div>
    
                <div class="col-lg-6 col-md-12">
                    <div class="banner-image">
                        <img [src]="banner.images3.mainImage" alt="image">
                        <img [src]="banner.images3.backgroundImage" alt="image">
                    </div>
                </div>
            </div>
           </div>
           <div class="shape-img1 rotateme"><img src="assets/img/shape-image/1.png" alt="image"></div>
           <div class="shape-img2"><img src="assets/img/shape-image/2.png" alt="image"></div>
           <div class="shape-img3 rotateme"><img src="assets/img/shape-image/3.png" alt="image"></div>
           <div class="shape-img4"><img src="assets/img/shape-image/4.png" alt="image"></div>
           <div class="shape-img5"><img src="assets/img/shape-image/5.png" alt="image"></div>
           <div class="shape-img6"><img src="assets/img/shape-image/6.png" alt="image"></div>
           <div class="shape-img7"><img src="assets/img/shape-image/7.png" alt="image"></div>
           <div class="shape-img8"><img src="assets/img/shape-image/8.png" alt="image"></div>
        </div>
        
    </div>
</section>


<!-- home.component.html -->
<section class="boxes-area">
    <div class="container">
        <div class="row">
            <div *ngFor="let box of boxes" class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box" [ngClass]="box.bgClass">
                    <div class="icon">
                        <i [class]="box.icon"></i>
                    </div>
                    <h3>{{ box.heading }}</h3>
                    <p>{{ box.description }}</p>
                    <a [routerLink]="box.link" class="learn-more-btn">Learn More <i
                            class="flaticon-arrow-pointing-to-right"></i></a>
                    <div class="shape-box">
                        <img *ngFor="let shapeImage of box.shapeImages" [src]="shapeImage" alt="shape image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about.component.html -->
<section class="about-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img [src]="aboutContent.image" alt="About Us Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>{{ aboutContent.title }}</h2>
                    <p>{{ aboutContent.description }}</p>
                    <ul class="about-features-list">
                        <li *ngFor="let feature of aboutContent.features">
                            <i class="flaticon-check-mark"></i> {{ feature }}
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a [routerLink]="aboutContent.learnMoreLink" class="btn btn-primary">Learn More</a>
                        <a [href]="aboutContent.videoLink" class="video-btn popup-youtube">
                            <i class="flaticon-play-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-shape1">
        <img [src]="aboutContent.bgShape" alt="Background Shape">
    </div>
    <div class="shape-img9">
        <img [src]="aboutContent.shapeImg" alt="Shape Image">
    </div>
</section>

<!-- products.component.html -->
<section class="services-area ptb-120 pt-0">
    <div class="container">
        <div class="section-title">
            <span>{{ productsectionTitle.span }}</span>
            <h2>{{ productsectionTitle.h2 }}</h2>
            <a [routerLink]="productsectionTitle.link" class="btn btn-secondary">{{ productsectionTitle.linkText }}</a>
        </div>

        <div class="row">
            <div *ngFor="let product of products" class="col-md-4">
                <div class="single-product-box">
                    <div class="product-image">
                        <a [routerLink]="product.link">
                            <img [src]="product.image" alt="Product Image">
                        </a>
                    </div>
                    <div class="product-content">
                        <h3><a [routerLink]="product.link">{{ product.title }}</a></h3>
                        <p>{{ product.description }}</p>
                        <a [routerLink]="product.link" class="add-to-cart-btn btn btn-secondary">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--appointment section-->
<app-appointment></app-appointment>
<!--appointment section-->

<div class="partner-area bg-fff7f4 extra-pt">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>
<!-- home.component.html -->
<section class="testimonial-area ptb-120 pt-120">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">{{ testimonialsectionTitle.span }}</span>
            <h2>{{ testimonialsectionTitle.title }}</h2>
        </div>
    </div>

    <div class="testimonial-slides owl-carousel owl-theme">
        <div class="single-testimonial-item" *ngFor="let testimonial of testimonials">
            <p>"{{ testimonial.message }}"</p>
            <div class="client-info">
                <h3>{{ testimonial.name }}</h3>
              
            </div>
            <i class="flaticon-left-quotes-sign"></i>
        </div>
    </div>
</section>

<!-- home.component.html -->
<section class="blog-area ptb-120 bg-fff7f4">
    <div class="container">
        <div class="section-title text-center">
            <span class="bg-ff5d24">{{ blogsectionTitle.span }}</span>
            <h2>{{ blogsectionTitle.title }}</h2>
            <p>{{ blogsectionTitle.description }}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blogPost of blogPosts">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a [routerLink]="blogPost.link"><img [src]="blogPost.imageUrl" alt="image"></a>
                        <div class="date">{{ blogPost.date }}</div>
                    </div>
                    <div class="post-content">
                        <span>By: <a [routerLink]="blogPost.link">{{ blogPost.author }}</a></span>
                        <h3><a [routerLink]="blogPost.link">{{ blogPost.title }}</a></h3>
                        <p>{{ blogPost.excerpt }}</p>
                        <a [routerLink]="blogPost.link" class="learn-more-btn">Learn More <i
                                class="flaticon-arrow-pointing-to-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--https://www.ajantapharma.com/-->