<div class="page-title-area products_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">Lithicoxib MR</h2>
            <ul>
                <li><a href="/products">Products</a></li>
                <li>Lithicoxib MR Details</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1 rotateme"><img src="assets/img/shape-image/1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape-image/2.png" alt="image"></div>
    <div class="shape-img3 rotateme"><img src="assets/img/shape-image/3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape-image/4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape-image/5.png" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape-image/6.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape-image/7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape-image/8.png" alt="image"></div>
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image"><a><img
                                alt="image" src="assets/img/blog-3.png"></a>
                        <div class="date">Lithicoxib MR</div>
                    </div>
                   
                </div>
            </div>

    

            <div class="col-lg-8 col-md-12">
                <div class="product-details-desc about-content">
                    <h3>Lithicoxib-MR</h3>
                   
                   
                    <p>Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablets</p>
                  
                    <ul  class="about-features-list">
                        <li ><i  class="flaticon-check-mark"></i> Skeletal Muscle Spasm
                        </li>
                        <li ><i  class="flaticon-check-mark"></i>  Sprain & Strain
                        </li>
                        <li ><i  class="flaticon-check-mark"></i> Post-Operative Spasm & Pain
                        </li>
                        <li ><i  class="flaticon-check-mark"></i>  Acute/Chronic Low Back Pain
                        </li>
                      
                    </ul>
                   
                </div>
            </div>

        </div>
    </div>

    <!--<div class="related-products-area mt-20">
        <div class="container">
            <div class="section-title">
                <span>Our Products</span>
                <h2>Related Products</h2>
                <p>Sanjivani Healthcare offers premium health solutions with a focus on quality and effectiveness, ensuring comprehensive care for your needs.</p>
            </div>

            <div class="row">
               
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithi Joint</a></h3>
                           <p>Lithi Joint is a specialized supplement formulated to support joint health and flexibility. Enriched with natural ingredients, it helps reduce discomfort and improve mobility, promoting overall joint well-being and comfort.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithicoxib MR</a></h3>
                           <p>Lithicoxib MR provides effective relief from chronic pain and inflammation with its extended-release formula, ensuring consistent comfort and improved quality of life for long-term management.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section>
