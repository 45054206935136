import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  showToast: boolean = false;
  toastMessage: string = '';
  toastType: 'success' | 'error' = 'success';

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      const formData = this.contactForm.value;

      // Send email using email.js
      const emailParams = {
        from_name: formData.name,
        from_email: formData.email,
        phone_number: formData.phone_number,
        msg_subject: formData.msg_subject,
        message: formData.message
      };

      emailjs.send(
        'service_pw30jgi',     // Replace with your EmailJS Service ID
        'template_gw8ec63',    // Replace with your EmailJS Template ID
        emailParams,
        'FyKuWmyXSGb46qy5v'         // Replace with your EmailJS User ID
      ).then((response: EmailJSResponseStatus) => {
        this.toastType = 'success';
        this.toastMessage = 'Your inquiry has been sent successfully!';
        this.showToast = true;
        setTimeout(() => this.showToast = false, 3000);
        this.contactForm.reset();
      }).catch((error) => {
        this.toastType = 'error';
        this.toastMessage = 'Failed to send inquiry. Please try again.';
        this.showToast = true;
        setTimeout(() => this.showToast = false, 3000);
        console.error('Error sending inquiry:', error);
      });

    } else {
      this.validateFields();
    }
  }

  validateFields(): void {
    if (this.contactForm.get('name').invalid) {
      this.toastType = 'error';
      this.toastMessage = 'Name is required.';
      this.showToast = true;
    } else if (this.contactForm.get('email').invalid) {
      this.toastType = 'error';
      this.toastMessage = 'Please enter a valid email address.';
      this.showToast = true;
    } else if (this.contactForm.get('phone_number').invalid) {
      this.toastType = 'error';
      this.toastMessage = 'Phone number is required.';
      this.showToast = true;
    } else if (this.contactForm.get('msg_subject').invalid) {
      this.toastType = 'error';
      this.toastMessage = 'Subject is required.';
      this.showToast = true;
    } else if (this.contactForm.get('message').invalid) {
      this.toastType = 'error';
      this.toastMessage = 'Message is required.';
      this.showToast = true;
    }
    setTimeout(() => this.showToast = false, 3000);
  }
}
