<div class="page-title-area products_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">Lithi Joint</h2>
            <ul>
                <li><a href="/products">Products</a></li>
                <li>Lithi Joint Details</li>
            </ul>
        </div>
    </div>
   
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image"><a><img
                                alt="image" src="assets/img/blog-2.png"></a>
                        <div class="date">Lithi Joint</div>
                    </div>
                   
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="product-details-desc about-content">
                    <h3>Lithi Joint</h3>
                   
                   
                    <p>Cissus Quadrangularis Linn Extract 500 mg, Tricalcium Phosphate 375 mg eq. to Elemental Calcium 145 mg eq. to Elemental Phosphorus 74 mg, Withania Somnifera Extract 50 mg, Ergocalciferol (Vitamin D2) 130 IU, Vitamin K2-7 45 mcg Tablets</p>
                  
                    <ul  class="about-features-list">
                        <li ><i  class="flaticon-check-mark"></i> Bone Fracture
                        </li>
                        <li ><i  class="flaticon-check-mark"></i>  Weak Bones
                        </li>
                        <li ><i  class="flaticon-check-mark"></i> Delayed union of fractures
                        </li>
                        <li ><i  class="flaticon-check-mark"></i> Osteomyelitis Stress fractures
                        </li>
                        <li ><i  class="flaticon-check-mark"></i> Post Menopausal Osteoporosis Senile Osteoporosis
                        </li>
                        <li ><i  class="flaticon-check-mark"></i> Patients with low BMD
                        </li>
                       
                    </ul>
                   
                </div>
            </div>

        </div>
    </div>

    <!--<div class="related-products-area mt-20">
        <div class="container">
            <div class="section-title">
                <span>Our Products</span>
                <h2>Related Products</h2>
                <p>Sanjivani Healthcare offers premium health solutions with a focus on quality and effectiveness, ensuring comprehensive care for your needs.</p>
            </div>

            <div class="row">
               
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithi Joint</a></h3>
                           <p>Lithi Joint is a specialized supplement formulated to support joint health and flexibility. Enriched with natural ingredients, it helps reduce discomfort and improve mobility, promoting overall joint well-being and comfort.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithicoxib MR</a></h3>
                           <p>Lithicoxib MR provides effective relief from chronic pain and inflammation with its extended-release formula, ensuring consistent comfort and improved quality of life for long-term management.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section>
