<div class="page-title-area products_01">
    <div class="container">
        <div class="page-title-content">
            <h2 class="text-white">Lithicoxib 90</h2>
            <ul>
                <li><a href="/products">Products</a></li>
                <li>Lithicoxib 90 Details</li>
            </ul>
        </div>
    </div>
   
</div>

<section class="product-details-area ptb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image"><a><img
                                alt="image" src="assets/img/products/2.png"></a>
                        <div class="date">Lithicoxib 90</div>
                    </div>
                   
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="product-details-desc about-content">
                    <h3>Lithicoxib 90</h3>
                   
                   
                    <p>Lithicoxib 90 is a potent NSAID (Non-Steroidal Anti-Inflammatory Drug) containing etoricoxib, specifically designed to provide powerful relief from pain and inflammation. This medication is particularly effective for managing chronic conditions such as arthritis, where it plays a crucial role in alleviating joint pain, swelling, and stiffness.
<br><br>
                        Lithicoxib 90 works by inhibiting the enzyme responsible for producing pain-inducing prostaglandins, leading to significant and lasting relief. Patients using Lithicoxib 90 can experience improved mobility and a better quality of life, as it reduces the discomfort associated with inflammatory conditions. This tablet is an essential part of managing long-term inflammatory diseases, offering a reliable solution for those seeking effective and sustained symptom control.</p>
                  
                 
                   
                </div>
            </div>

        </div>
    </div>

    <!--<div class="related-products-area mt-20">
        <div class="container">
            <div class="section-title">
                <span>Our Products</span>
                <h2>Related Products</h2>
                <p>Sanjivani Healthcare offers premium health solutions with a focus on quality and effectiveness, ensuring comprehensive care for your needs.</p>
            </div>

            <div class="row">
               
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithi Joint</a></h3>
                           <p>Lithi Joint is a specialized supplement formulated to support joint health and flexibility. Enriched with natural ingredients, it helps reduce discomfort and improve mobility, promoting overall joint well-being and comfort.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products">
                                <img src="assets/img/shop-img1.jpg" alt="image">
                            </a>
                           
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/products">Lithicoxib MR</a></h3>
                           <p>Lithicoxib MR provides effective relief from chronic pain and inflammation with its extended-release formula, ensuring consistent comfort and improved quality of life for long-term management.</p>
                            <a routerLink="/products" class="add-to-cart-btn btn btn-secondary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</section>

