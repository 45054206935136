import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  appointmentForm: FormGroup;
 
  boxes = [
    {
      icon: 'flaticon-laboratory',
      heading: 'Innovative Therapies',
      description: 'Leading the way in advanced medical solutions for better patient care.',
      link: '/home',
      shapeImages: [
        'assets/img/shape-image/9.png',
        'assets/img/shape-image/10.png'
      ]
    },
    {
      icon: 'flaticon-microscope',
      heading: 'Cutting-edge Research',
      description: 'Empowering breakthroughs through relentless scientific exploration.',
      link: '/home',
      shapeImages: [
        'assets/img/shape-image/9.png',
        'assets/img/shape-image/10.png'
      ],
      bgClass: 'bg-43c784'
    },
    {
      icon: 'flaticon-laboratory-1',
      heading: 'Excellence in Outcomes',
      description: 'Transforming lives with superior healthcare advancements.',
      link: '/home',
      shapeImages: [
        'assets/img/shape-image/9.png',
        'assets/img/shape-image/10.png'
      ],
      bgClass: 'bg-f59f00'
    }
  ];

  aboutContent = {
    image: 'assets/img/ai-generated-8433537_640.jpg',
    title: 'Discoveries That Deliver Your Solutions',
    description: 'Moonray Pharmaceuticals Pvt Ltd., A unit of the Sanjivani Healthcare, we are committed to developing a positive treatments and innovative solutions that address your healthcare needs. Our goal is to improve life by creating effective therapies and enhancing patient outcomes. Our dedicated team of experts uses advanced research and the latest technologies to push the limits of medical science.',
    features: [
      'Chemical Research',
      'Pathology Testing',
      'Sample Preparation',
      'Healthcare Labs',
      'Advanced Microscopy',
      'Advanced Robotics',
      'Environmental Testing',
      'Anatomical Pathology'
    ],
    learnMoreLink: '/about-us',
    
  };

  productsectionTitle = {
    span: 'Products',
    h2: 'You can rely on the standard of our products as we have years of experience in the industry.',
    link: '/products',
    linkText: 'Tell Us More'
  };

  products = [
    {
      image: 'assets/img/blog-img9.jpg',
      title: 'Nutriget-9G',
      description: 'Nutriget 9G Capsules is a blend of omega 3, green tea extract, ginkgo biloba extract, ginseng, grape seed extract, ginger root extract, green coffee extract, garlic powder, and licorice extract with L Carnitine and a multipack of vitamins and minerals in a capsule.',
      link: '/nutriget-9-g'
    },
    {
      image: 'assets/img/blog-2.png',
      title: 'Lithi Joint',
      description: 'Lithi Joint comprise of consist of 500 mg Cissus Quadrangularis Linn Extract, 375 mg tricalcium phosphate (which yields 145 mg elemental calcium and 74 mg elemental phosphorus), 50 mg Withania Somnifera Extract, 130 IU Vitamin D2 and 45 mcg Vitamin K2-7.',
      link: '/lithi-joint'
    },
    {
      image: 'assets/img/blog-3.png',
      title: 'Lithicoxib MR',
      description: 'Terminate the Spasm Effectively with Lithicoxib MR - Etoricoxib 60 mg + Thiocolchicoside 4 mg Tablets.Skeletal Muscle Spasm, Sprain & Strain, Post-Operative Spasm & Pain, Acute/Chronic Low Back Pain.Potent, Faster & Prolonged Action.',
      link: '/lithicoxib-mr'
    },
    {
      image: 'assets/img/products/1.png',
      title: 'Nutriget D3 Drops',
      description: 'Nutriget D3 Drops is an oral preparation focused on vitamin D3, specifically Cholecalciferol (Vitamin D3) at 800 IU. It supports bone tissue, boosts immunity, and enhances overall health. Packed with essential vitamins and natural constituents, Nutriget D3 promotes vitality and well-being.',
      link: '/nutriget-d'
    },
    {
      image: 'assets/img/products/2.png',
      title: 'Lithicoxib 90',
      description: 'Lithicoxib 90 contains etoricoxib, a powerful NSAID tablet specially designed for fast, effective pain relief and inflammation control. It is used for various conditions like arthritis, offering long-lasting symptom management and improving overall joint mobility and flexibility.',
      link: '/lithicoxib90'
    },
    {
      image: 'assets/img/products/3.png',
      title: 'Nutriget-L Drops',
      description: 'Nutriget-L Drops is a dietary supplement rich in essential vitamins and minerals, including <b>flaxseed oil, a rich source of omega-3 fatty acids</b>, that supports overall health. It aids in immune function, energy levels, and general wellness with its balanced nutrient formula.',
      link: '/nutriget-l'
    },
   ];

  testimonialsectionTitle = {
    span: 'Testimonial',
    title: 'What’s Clients Response About'
  };

  blogsectionTitle = {
    span: 'News Update',
    title: 'News & Blog',
    description: 'Stay updated with the latest healthcare advancements, research breakthroughs, and company news from Moonray Pharmaceuticals. Explore insightful articles and expert opinions.'
  };

  blogPosts = [
    {
      date: 'Nutriget-9G',
      author: 'Akash Sharma',
      title: 'Nutriget-9G: Revolutionizing Nutrition for a Healthier Future',
      excerpt: 'Discover Nutriget-9G, a cutting-edge supplement offering essential nutrients to boost energy and enhance wellness. Embrace a healthier lifestyle with our advanced formula designed for optimal health and vitality.',
      imageUrl: 'assets/img/Sanjivani-Healthcare-01.jpg',
      link: '/products'
    },
    {
      date: 'Lithi Joint',
      author: 'Mohit Kummar',
      title: 'Lithi Joint: Advanced Joint Support for Active Living',
      excerpt: 'Explore Lithi Joint, a revolutionary supplement designed to support joint health, reduce discomfort, and enhance mobility. Achieve a more active and pain-free lifestyle with our scientifically formulated solution.',
      imageUrl: 'assets/img/Sanjivani-Healthcare-02.jpg',
      link: '/products'
    },
    {
      date: 'Lithicoxib MR',
      author: 'Akash Sharma',
      title: 'Lithicoxib MR: Enhanced Relief for Chronic Pain',
      excerpt: 'Discover Lithicoxib MR, an advanced medication offering long-lasting relief from chronic pain and inflammation. It targets discomfort at its source, helping you regain mobility and improve your quality of life.',
      imageUrl: 'assets/img/Sanjivani-Healthcare-03.jpg',
      link: '/products'
    }
  ];

  testimonials = [
    {
      message: 'Moonray Pharmaceuticals has been a game-changer for my health. Their innovative treatments are effective and reliable.',
      name: 'Mohit Anand',
    },
    {
      message: 'Exceptional service and cutting-edge solutions. Moonray commitment to excellence is evident in every product.',
      name: 'Dhruv Sharma',
     },
    {
      message: 'Moonray Pharmaceutical groundbreaking therapies have significantly enhanced my quality of life. Thank you for your dedication!',
      name: 'Sandeep Sharma',
    },
    {
      message: 'Outstanding care and innovative solutions. Moonray Pharmaceuticals Pharmaceutical truly makes a difference in healthcare.',
      name: 'Koushul Sethi',
     
    },
    {
      message: 'Moonray Pharmaceuticals of Sanjivani Healthcare advanced therapies have provided me with remarkable health improvements.',
      name: 'Lakshay kapoor',
    }
  ];

  constructor(
    private meta: Meta,
    private title: Title,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Welcome to Moonray Pharmaceutical: Innovating Healthcare, Transforming Lives');
    this.meta.updateTag({ name: 'description', content: 'Moonray Pharmaceutical is dedicated to advancing healthcare through innovative research and development. Discover our cutting-edge treatments and solutions designed to improve patient outcomes.' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:title', content: 'Moonray Pharmaceutical - Innovating Healthcare' });
    this.meta.updateTag({ property: 'og:description', content: 'Explore Moonray Pharmaceutical’s pioneering research and development in healthcare. Our mission is to enhance patient care with innovative solutions.' });
    this.meta.updateTag({ property: 'og:url', content: '' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Moonray Pharmaceutical' });
    this.meta.updateTag({ property: 'og:image', content: '' });
    this.meta.updateTag({ rel: 'canonical', href: '' });

    this.appointmentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });

    this.loadDynamicContent();
  }

  loadDynamicContent(): void {
    // Load dynamic content if needed (e.g., from an API or service)
  }

  onSubmit(): void {
    if (this.appointmentForm.valid) {
      console.log(this.appointmentForm.value);
      this.toastr.success('Your appointment request has been submitted successfully!', 'Success');
      this.appointmentForm.reset();
    } else {
      this.toastr.error('Please fill in the required fields correctly.', 'Error');
      console.log('Form is invalid');
    }
  }
}


