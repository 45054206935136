import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/HomeComponent';
import { Home1Component } from './components/pages/home1/Home1Component';

import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { Nutriget9GComponent } from './components/pages/nutriget-9-g/nutriget-9-g.component';
import { LithicoxibMRComponent } from './components/pages/lithicoxib-mr/lithicoxib-mr.component';
import { LithiJointComponent } from './components/pages/lithi-joint/lithi-joint.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NutrigetDComponent } from './components/pages/nutriget-d/nutriget-d.component';
import { Lithicoxib90Component } from './components/pages/lithicoxib90/lithicoxib90.component';
import { NutrigetLComponent } from './components/pages/nutriget-l/nutriget-l.component';


const routes: Routes = [

 
 
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'about-us', component: AboutUsComponent, pathMatch: 'full'},
    {path: 'error', component: ErrorComponent, pathMatch: 'full'},
    {path: 'products', component: ProductsComponent, pathMatch: 'full'},
    {path: 'products-details', component: ProductsDetailsComponent, pathMatch: 'full'},
    { path: 'nutriget-9-g', component: Nutriget9GComponent, pathMatch: 'full' },
    { path: 'lithi-joint', component: LithiJointComponent, pathMatch: 'full' },
    { path: 'lithicoxib-mr', component: LithicoxibMRComponent, pathMatch: 'full' },
    { path: 'nutriget-d', component: NutrigetDComponent, pathMatch: 'full' },
    { path: 'lithicoxib90', component: Lithicoxib90Component, pathMatch: 'full' },
    { path: 'nutriget-l', component: NutrigetLComponent, pathMatch: 'full' },
    {path: 'contact', component: ContactComponent, pathMatch: 'full'},
    {path: 'coming-soon', component: ComingSoonComponent, pathMatch: 'full'},
    { path: '**', redirectTo: '' } ,
    {path: '**', component: ErrorComponent, pathMatch: 'full'} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }